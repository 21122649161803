@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 218 17% 13%;
    --foreground: 219 29% 9%;
 
    --primary: 184 71% 61%;
    --primary-foreground: 210 40% 98%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 130 43% 50%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 228 3% 68%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 45 94% 58%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 1 57% 45%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 218 17% 13%;
    --foreground: 219 29% 9%;
 
    --primary: 184 71% 61%;
    --primary-foreground: 210 40% 98%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 130 43% 50%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 228 3% 68%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }


}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}